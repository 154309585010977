import { navigate } from "gatsby";
import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import { courseSeoDetails } from "../../constants/courseSeoDetails";
import Showdown from "showdown";
import Layout from "../../components/Layout";
import { CoursePageContainer } from "../../components/CoursePageContainer";
import { INITIAL_LEARNER_COUNT } from "../../utils/localization";
import { useSelector } from "react-redux";

const SeriesPage = (props) => {
  const { pageContext } = props;
  const { course, courses } = pageContext;
  const userCourses = useSelector((state) => state.userCourses);
  let pageTitle, pageDescription, pageSchema;
  pageTitle = course?.title ?? courseSeoDetails.default.pageTitle;
  pageDescription =
    course?.description ?? courseSeoDetails.default.pageDescription;
  pageSchema = course?.pageSchema;

  useEffect(() => {
    if (isEmpty(pageContext)) {
      navigate("/404");
    }
  });

  const userSubscribedSeries = userCourses?.series.filter(
    (subscribedSeries) => subscribedSeries?.id === course?.id
  );

  return (
    <Layout
      title={pageTitle}
      description={pageDescription}
      schemaMarkup={pageSchema}
    >
      <CoursePageContainer
        series
        coursesOffered={course?.courses}
        courseId={course?.id}
        title={course?.title}
        description={course?.shortDescription}
        course_length={course?.duration}
        price={course?.price}
        learners={course?.learnersCount}
        course_level={course?.level}
        allCourses={courses || []}
        Faqs={course?.faqs}
        continuation_courses={course?.relatedSeries ?? []}
        chaptersCount={course?.chaptersCount}
        hasSubscribed={userSubscribedSeries[0]?.hasSubscribed}
      />
    </Layout>
  );
};

export default SeriesPage;
